import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Button, message, Table } from "antd";
import { FaC, FaPenToSquare } from "react-icons/fa6";

const ReportPage = () => {
  const [reportData, setReportData] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [popupModal, setPopupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  //handle amount edit
  const handlePriceEdit = (value) => {
    setSelectedValue(value);
    setPopupModal(true);
  };

  const handleUpdateReport = async () => {
    setIsLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/profit-reports/update`,
      selectedValue
    );

    if (!res?.status === 200) {
      setIsLoading(false);
      return message.error(res?.data?.message);
    }

    setIsLoading(false);

    getAllReport();
    setPopupModal(false);
    message.success(res?.data?.message);
  };

  const getAllReport = async () => {
    try {
      dispatch({
        type: "SHOW_LOADING",
      });
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/profit-reports/all`
      );
      setReportData(data);
      dispatch({ type: "HIDE_LOADING" });
      console.log(data);
    } catch (error) {
      dispatch({ type: "HIDE_LOADING" });
      console.log(error);
    }
  };
  //useEffect
  useEffect(() => {
    getAllReport();
  }, []);

  const columns = [
    {
      title: "Month",
      dataIndex: "_id",
      render: (id, record) => (
        <div className="text-capitalize">{record.month}</div>
      ),
    },
    { title: "Year", dataIndex: "year" },
    {
      title: "Expenses",
      dataIndex: "expenses",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
    },
    {
      title: "Profit",
      dataIndex: "_id",
      render: (id, record) => (
        <div className="edit-price-outer">
          <b>{record.profit}</b>

          <button
            className="action-btn"
            onClick={() => handlePriceEdit(record)}
          >
            <FaPenToSquare size={15} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <div className="report-header">
        <h1>Reports</h1>
      </div>
      <Table
        columns={columns}
        dataSource={reportData}
        bordered
        pagination={false}
      />
      {popupModal && (
        <div className="overlay" onClick={() => setPopupModal(false)} />
      )}

      <div className={`selling-price ${popupModal && "active"}`}>
        <h3 style={{ margin: 0 }}>Report</h3>
        <input
          type="number"
          placeholder="Expenses"
          value={selectedValue?.expenses || ""}
          onChange={(e) =>
            setSelectedValue({ ...selectedValue, expenses: e.target.value })
          }
          className="price-input"
        />

        <input
          type="number"
          placeholder="Revenue"
          value={selectedValue?.revenue || ""}
          onChange={(e) =>
            setSelectedValue({ ...selectedValue, revenue: e.target.value })
          }
          className="price-input"
        />

        <input
          type="number"
          placeholder="Profit"
          value={selectedValue?.profit || ""}
          onChange={(e) =>
            setSelectedValue({ ...selectedValue, profit: e.target.value })
          }
          className="price-input"
        />

        <Button
          type="primary"
          onClick={() => {
            handleUpdateReport();
          }}
        >
          {isLoading ? <FaC size={20} className="loader" /> : "Update"}
        </Button>
      </div>
    </DefaultLayout>
  );
};

export default ReportPage;
